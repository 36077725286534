import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import TextLoop from "react-text-loop";

import Image from "../../../components/image";
import { Container, Wrapper, Row, Box } from "../../../components/util";
import {
  CallToAction,
  CountrySpecificContent,
} from "../../../components/site";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import PencilGraphic from "../../../images/misc/running_pencil.svg";

const FeaturesContainer = styled(Container)`
  // padding-top: 51px;

  .features-hero{
    background-color: red;

  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-top: 0;
  }
  h4{
    margin-top: 10px;
    font-size: 24px;
  }
  h2{
    font-size: 32px;
  }
`;


const FeaturesHero = styled(Box)`
  margin-top: 60px;
  .features-hero{
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  background-color: #CFF0D3;
  h1{
    font-size: 48px;
  }
  h4{
    margin-top: 20px;
    font-size: 32px;
  }
  img{
    padding-left: 30px;
    margin-top: 0px;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      display: none;
    }
  }
`;

const FeatureTemplate = (props) => {
  return (
    <Row
      alignCenter
      flipRow={props.i % 2 !== 0 ? true : false}
      stackGap={70}
      key={props.i}
    >
      <Box stackGap={30} size={50} centerOnMobile>
        <div>
          <h2>{props.item.feature}</h2>
          <h4>{props.item.description}</h4>
        </div>
        {/* <Link
          className="link-floating -centerOnMobile"
          to={props.item.link}
        >
          Learn More
        </Link> */}
      </Box>
      <Box size={50}>
        <Image
          filename={props.item.image}
          alt={props.item.feature + " | Karmly Feature"}
          addShadow
          rounded
          centerImage
        />
      </Box>
    </Row>
  );
};

const Features = (props) => {
  const features = [
    {
      feature: "Dashboard",
      description:
        "Monitor business performance, notifications and tasks requiring attention from a single screen.",
      link: "/features/dashboard",
      image: "InvoxyFeature_Dashboard.png",
      country: null,
    },
    {
      feature: "Onboarding",
      description:
        "Create a set of tasks for your contractors to complete when they first login.",
      link: "/features/onboarding",
      image: "Invoxy_Onboarding_Header.png",
      country: null,
    },
    {
      feature: "Timesheet Management",
      description:
        "Keep your contractors and clients happy with simple timesheet entry and one-click approvals.",
      link: "/features/timesheets",
      image: "InvoxyFeature_TimesheetManagement.png",
      country: null,
    },
    {
      feature: "eInvoicing",
      description:
        "Send and receive invoices via a dedicated secure global network. Direct invoices from your accounting software to your clients with no emails, pdfs or manual handling.",
      link: "/features/einvoicing",
      image: "InvoxyFeature_eInvoicing2.png",
      country: ["New Zealand", "Australia"],
    },
    {
      feature: "Mobile Timesheet App",
      description:
        "Let contractors enter hours, breaks and notes against Placements and Work they’ve been assigned.",
      link: "/features/mobile-timesheets",
      image: "InvoxyMobile_TimeEntry.png",
      country: null,
    },
    {
      feature: "Messaging",
      description:
        "Send bulk reminder emails to chase up overdue timesheets and approvals.",
      link: "/features/messaging",
      image: "InvoxyFeature_SmartMessaging.png",
      country: null,
    },
    {
      feature: "Invoicing",
      description:
        "Create and send invoice batches in a few clicks with invoices tailored to each client’s exact requirements.",
      link: "/features/invoicing",
      image: "InvoxyFeature_InvoiceBuilder.png",
      country: null,
    },
    {
      feature: "Reporting",
      description:
        "Build your own reports to keep track of your key recruitment metrics.",
      link: "/features/reporting",
      image: "InvoxyFeature_Forecast.png",
      country: null,
    },
  ];

  return (
    <Layout>
      <Seo
        title="Features List for Karmly | Recruitment Management made easy"
        description="All the features you need to run your recruitment business easily. Let Karmly take care of Timesheets, Approvals, Invoicing, Reporting and more."
        pathname={props.location.pathname}
      />

      <FeaturesHero>
        <Wrapper className="features-hero">
          <div>
            <h1>
              Let Karmly take care of{" "}
              <TextLoop interval={3000} mask={true}>
                <span css={{ color: "#5BA079" }}>Timesheets</span>
                <span css={{ color: "#5BA079" }}>Approvals</span>
                <span css={{ color: "#5BA079" }}>Invoicing</span>
                <span css={{ color: "#5BA079" }}>Reporting</span>
                <span css={{ color: "#5BA079" }}>Onboarding</span>
              </TextLoop>
            </h1>
            <h4>Running your recruitment business has never been this easy.</h4>
          </div>
          <img src={PencilGraphic} alt="Karmly | Features">
          </img>


        </Wrapper>
      </FeaturesHero>
      <FeaturesContainer>
        <Wrapper>
          {features.map((item, i) => {
            if (item.country != null) {
              return (
                <CountrySpecificContent forCountry={item.country}>
                  <FeatureTemplate item={item} i={i} />
                </CountrySpecificContent>
              );
            } else {
              return (
                <FeatureTemplate item={item} i={i} />
              )
            }
          })}
        </Wrapper>
      </FeaturesContainer>
      <CallToAction />
    </Layout>
  );
};

export default Features;
